import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import httpService from "../../services/HttpService";

export const fetchRoleplayConfig = createAsyncThunk(
  "roleplayConfigDynamic/createConversation",
  async (
    { email, name, courseId, premiumCharacter, orgId },
    { rejectWithValue },
  ) => {
    const requestBody = {
      email: email,
      name: name,
      course_id: courseId,
      premium_character: premiumCharacter,
      organisation_id: orgId,
    };
    console.debug("Request body for createConversation:", requestBody);

    try {
      const data = await httpService.performPostRequest(
        "create_conversation",
        requestBody,
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const updateConversationId = createAsyncThunk(
  "roleplayConfigDynamic/updateConversationId",
  async ({ oldConversationId, newConversationId }, { rejectWithValue }) => {
    const requestBody = {
      old_conversation_id: oldConversationId,
      new_conversation_id: newConversationId,
    };
    console.debug("Request body for updateConversationID:", requestBody);

    try {
      const response = await httpService.performPostRequest(
        "update_conversation_id",
        requestBody,
      );
      return response.message; // Assuming the backend sends a success message
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

const roleplayConfigDynamicSlice = createSlice({
  name: "roleplayConfigDynamic",
  initialState: {
    data: {},
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    updateStatus: "idle",
    updateMessage: null,
    error: null,
    currentConversationId: null,
  },
  reducers: {
    resetRoleplayConfigDynamic: (state) => {
      state.data = {};
      state.status = "idle";
      state.error = null;
      state.currentConversationId = null;
    },
    resetUpdateStatus: (state) => {
      state.updateStatus = "idle";
      state.updateMessage = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoleplayConfig.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRoleplayConfig.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchRoleplayConfig.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(updateConversationId.pending, (state) => {
        state.updateStatus = "loading";
      })
      .addCase(updateConversationId.fulfilled, (state, action) => {
        state.updateStatus = "succeeded";
        state.updateMessage = action.payload;
        state.currentConversationId = action.meta.arg.newConversationId;
      })
      .addCase(updateConversationId.rejected, (state, action) => {
        state.updateStatus = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetRoleplayConfigDynamic, resetUpdateStatus } =
  roleplayConfigDynamicSlice.actions;

export default roleplayConfigDynamicSlice.reducer;
