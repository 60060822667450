import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { motion } from "framer-motion";

import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import DashboardLayout from "layouts/DashboardLayout";
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import DataTable from "components/DataTable";
import MKTypography from "components/MKTypography";
import PlaybackPlayerHolder from "components/PlaybackPlayerHolder";
import MultipleStatisticsCard from "components/StatisticsCards/MultipleStatisticsCard";
import { fetchOrganisationConversations } from "utils/OrganisationUtils";
import authorsTableData from "./authorsTableData";

const MyReport = () => {
  const [showPlayerCard, setShowPlayerCard] = useState(false);
  const navigate = useNavigate();

  const handleShowPlayerCard = () => {
    setShowPlayerCard((current) => !current);
  };

  //Setting this up for an organisation
  const orgId = useSelector((state) => state.user.orgId);
  const [conversationCounts, setConversationCounts] = useState(0);
  const [rankedUsers, setRankedUsers] = useState([]);
  const [bestCall, setBestCall] = useState(null);

  useEffect(() => {
    if (orgId) {
      fetchOrganisationConversations(orgId)
        .then(
          ({ totalConversations, rankedUsersAndConversations, bestCall }) => {
            setConversationCounts(totalConversations);
            setRankedUsers(rankedUsersAndConversations);
            setBestCall(bestCall);

            console.log("Conversations: ", totalConversations);
            console.log(
              "Ranked Users and Conversations: ",
              rankedUsersAndConversations,
            );
          },
        )
        .catch((error) =>
          console.error("Failed to fetch conversations", error),
        );
    } else {
      navigate("/not-available");
    }
  }, [orgId]);

  const { columns, rows } = authorsTableData(rankedUsers);

  // Animations
  const tableVariants = {
    hidden: { clipPath: "inset(0% 0% 100% 0%)" },
    visible: {
      clipPath: "inset(0% 0% 0% 0%)",
      transition: { duration: 0.5, ease: "easeInOut" },
    },
  };

  return (
    <DashboardLayout>
      <MKBox p={3}>
        <MKBox pb={3}>
          <MKTypography variant="h4">
            {`${orgId?.split("_")[1]?.charAt(0).toUpperCase() + orgId?.split("_")[1]?.slice(1).toLowerCase() || "Loading..."} - Week 25`}
          </MKTypography>
        </MKBox>
        <MKBox p={3} mb={3}>
          <Grid container spacing={6}>
            <Grid item xs={6} md={4}>
              <MultipleStatisticsCard
                data={[
                  {
                    title: "Total Calls",
                    count: conversationCounts,
                    percentage: {
                      color: "success",
                      amount: "5%",
                      label: "increase",
                    },
                  },
                ]}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <MultipleStatisticsCard
                data={[
                  {
                    title: "Average Score",
                    count: 3.45,
                    percentage: {
                      color: "success",
                      amount: "2%",
                      label: "increase",
                    },
                  },
                ]}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <MultipleStatisticsCard
                data={[
                  {
                    title: "Total Minutes Calling",
                    count: 112,
                    percentage: {
                      color: "error",
                      amount: "8%",
                      label: "decrease",
                    },
                  },
                ]}
              />
            </Grid>
          </Grid>
        </MKBox>

        <MKBox pb={6} bgColor="white" borderRadius="lg" shadow="xs">
          <MKBox p={3}>
            <MKTypography variant="h5">Call of the week</MKTypography>
          </MKBox>
          <MKBox
            mx={2}
            py={3}
            mb={-3}
            px={2}
            bgColor="secondary"
            borderRadius="lg"
            shadow="sm"
            style={{
              position: "relative",
              cursor: "pointer",
            }}
            onClick={handleShowPlayerCard}
          >
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <MKTypography variant="h6" color="dark">
                  {bestCall?.name}
                </MKTypography>
              </Grid>
              <Grid item>
                <MKTypography variant="h6" color="dark">
                  Score: {bestCall?.average_score}
                </MKTypography>
              </Grid>
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <MKTypography variant="h6" color="dark">
                      Best Area: {bestCall?.strongest_criteria}
                    </MKTypography>
                  </Grid>
                  <Grid item>
                    <MKButton variant="text" color="dark" iconOnly={true}>
                      <Icon>
                        {showPlayerCard ? "expand_less" : "expand_more"}
                      </Icon>
                    </MKButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </MKBox>
          {showPlayerCard && (
            <motion.div
              initial="hidden"
              animate="visible"
              variants={tableVariants}
              style={{ overflow: "visible" }} // Ensure that children are not clipped
            >
              <MKBox pt={3}>
                <PlaybackPlayerHolder url={bestCall?.call_recording_url} />
              </MKBox>
            </motion.div>
          )}
        </MKBox>
        <motion.div layout>
          <MKBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <MKBox bgColor="white" borderRadius="lg" shadow="xs">
                  <MKBox p={3}>
                    <MKTypography variant="h5">
                      Team Ranking - Week 25
                    </MKTypography>
                  </MKBox>
                  <MKBox>
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={true}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      pagination={{ variant: "gradient", color: "primary" }}
                    />
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        </motion.div>
      </MKBox>
    </DashboardLayout>
  );
};

export default MyReport;
