import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "pages/NewView/LoadingOverlay";

const RedirectPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/callview");
    }, 500);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, [navigate]);

  return <LoadingOverlay isOpen={true} />;
};

export default RedirectPage;
