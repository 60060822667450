import React from "react";
import PropTypes from "prop-types"; // Add this import
import MKBox from "components/MKBox";
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";

const MKModal = ({ open, handleClose, canClose, children }) => {
  const handleUserClose = () => {
    if (canClose) {
      handleClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleUserClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Slide direction="right" in={open} timeout={500}>
        <MKBox
          borderRadius="lg"
          sx={{
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          {children}
        </MKBox>
      </Slide>
    </Modal>
  );
};

MKModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired, // Add validation for handleClose
  canClose: PropTypes.bool,
  children: PropTypes.node, // children is optional, but you can mark it as required if needed
};

MKModal.defaultProps = {
  canClose: true,
};

export default MKModal;
