import React, { useEffect, useState } from "react";
import { useLocation, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ErrorBoundary, useErrorBoundary } from "react-error-boundary";
import "bootstrap/dist/css/bootstrap.min.css";

import "./services/logger/LogRocket";
import { useAuth } from "./services/firebase/AuthContext";
import { useUserManagement } from "./hooks/useUserManagement";
import { createRoutes } from "./routes";
import AppLayout from "./AppLayout";
import { openInformationModal } from "./components/BaseModal/InformationModal";
import isSafari from "./utils/browserCheck";
import ErrorModal from "./components/BaseModal/ErrorModal";
import { useMaterialUIController } from "context/materialUIController";
import EnterNameModal from "components/EnterNameModal";
import { sendErrorEmail } from "./services/errorService";
import ConfirmationModal from "components/BaseModal/ConfirmationModal";

const App = () => {
  useEffect(() => {
    if (isSafari()) {
      openInformationModal({
        title: "Opss! We might have to change browsers!",
        content:
          "Skylar is optimized for Chrome. Please use Chrome to get the best experience.",
      });
    }
  }, []);

  // setup user
  const { authenticatedCurrentUser, enterNameModalRef } = useUserManagement();
  const userEmail = useSelector((state) => state.user.email);
  const dispatch = useDispatch();

  // const additionalNavbarRoute = useSelector(
  //   (state) => state.user.additionalNavbarRoute
  // );
  const location = useLocation();
  const { signOut } = useAuth();
  // error setup
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    const handleSocketError = (e) => {
      console.log("We caught an error:", e.detail);
      showBoundary(e.detail);
    };

    window.addEventListener("socketError", handleSocketError);
    window.addEventListener("playAudioError", handleSocketError);

    return () => {
      window.removeEventListener("socketError", handleSocketError);
      window.removeEventListener("playAudioError", handleSocketError);
    };
  }, [showBoundary]);

  // Define the transparentNavBarPaths and check if the current path is one of them
  const transparentNavBarPaths = [
    "/",
    "/signup",
    "/chrisVoss",
    "/mone",
    "/theSDRnewsletter",
    "/pitchfolio",
    "/cfo",
    "/gtm",
    "/cs",
  ];
  const isTransparentNavBar = transparentNavBarPaths.includes(
    location.pathname,
  );

  // const routes = [
  //   // Your other routes here
  //   ...(additionalNavbarRoute ? [additionalNavbarRoute] : []),
  // ];

  console.log("Current location:", location.pathname);
  const [additionalRoute, setAdditionalRoute] = useState(null);

  useEffect(() => {
    // Example of setting up an additional navbar route based on the current path
    if (
      location.pathname === "/theSDRnewsletter" ||
      location.pathname === "/pitchfolio"
    ) {
      const newRoute = {
        name: "Try Pitchfolio",
        icon: "open_in_new",
        href: "https://pitchfolio.io/",
      };
      setAdditionalRoute(newRoute);
    }
  }, [location, dispatch]);
  const { navMetadata } = createRoutes(additionalRoute);
  const sidenavRoutes = navMetadata.filter((route) => route.sideNav);
  const pagenavRoutes = navMetadata.filter((route) => route.pageNav);

  const handleSignOut = async () => {
    try {
      await signOut();
      console.log("Signed out successfully");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const [controller] = useMaterialUIController();
  const { layout } = controller;

  return (
    <AppLayout
      sidenavRoutes={sidenavRoutes}
      pagenavRoutes={pagenavRoutes}
      layout={layout}
      isTransparentNavBar={isTransparentNavBar}
      authenticatedCurrentUser={authenticatedCurrentUser}
      handleSignOut={handleSignOut}
    >
      <ErrorBoundary
        FallbackComponent={ErrorModal}
        onReset={() => {
          window.location.href = "/";
        }}
        onError={(error, errorInfo) => {
          console.log("ErrorBoundary activated:", error, errorInfo);
          sendErrorEmail(error, errorInfo, userEmail || "Unknown");
        }}
      >
        <Outlet />
        <EnterNameModal ref={enterNameModalRef} />
        <ConfirmationModal />
      </ErrorBoundary>
    </AppLayout>
  );
};

export default App;
