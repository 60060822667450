// userSlice.js
import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    isAuthenticated: false,
    authLevel: null,
    selectedRoleplay: null,
    rank: 1, // default rank
    displayName: null, // new state variable
    email: null, // new state variable
    totalRoleplaysToday: 0, // new state variable
    orgId: null, // new state variable
  },
  reducers: {
    setAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setAuthLevel: (state, action) => {
      state.authLevel = action.payload;
    },
    setSelectedRoleplay: (state, action) => {
      state.selectedRoleplay = action.payload;
    },
    setRank: (state, action) => {
      state.rank = action.payload;
    },
    setDisplayName: (state, action) => {
      state.displayName = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setTotalRoleplaysToday: (state, action) => {
      console.log("Setting total roleplays today to:", action.payload);
      state.totalRoleplaysToday = action.payload;
    },
    setOrgId: (state, action) => {
      state.orgId = action.payload;
    },
  },
});

export const {
  setAuthenticated,
  setAuthLevel,
  setSelectedRoleplay,
  setRank,
  setDisplayName,
  setEmail,
  setTotalRoleplaysToday,
  setOrgId,
} = userSlice.actions;

export default userSlice.reducer;
