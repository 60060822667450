import React, { useState, useEffect, useContext, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import Vapi from "@vapi-ai/web";
// import MockVapi from "_mocks_/MockVapi";
import CallViewCard from "components/CallViewCard";
import useGreeting from "hooks/useGreeting/useGreeting";
import { setCallState } from "../../features/callView/callViewSlice";
import roleplayConfig from "config/roleplayConfig";
import { useAfterCallViewNavigation } from "utils/afterCallViewNavigation";
import { useAfterCallViewFeedback } from "utils/afterCallViewFeedback";
import UserOnboardingModal from "pages/NewView/UserOnboardingModal";
import {
  fetchRoleplayConfig,
  resetRoleplayConfigDynamic,
  resetUpdateStatus,
} from "features/roleplayConfigDynamic/roleplayConfigDynamicSlice";
import LoginModal from "./LoginModal";
import { AuthContext } from "services/firebase/AuthContext";
import DashboardLayout from "layouts/DashboardLayout";
import ErrorModal from "components/BaseModal/ErrorModal";
import { sendErrorEmail } from "services/errorService";
import {
  useMaterialUIController,
  setMiniSidenav,
} from "context/materialUIController";

import useNavigationPrompt from "hooks/useNavigationPrompt";

const NewView = () => {
  const userName = useSelector((state) => state.user.displayName);
  const userEmail = useSelector((state) => state.user.email);
  const email = useSelector((state) => state.user.email);
  const currentConversationId = useSelector(
    (state) => state.roleplayConfigDynamic.currentConversationId,
  );

  const totalRoleplaysToday = useSelector(
    (state) => state.user.totalRoleplaysToday,
  );
  const orgId = useSelector((state) => state.user.orgId);

  const selectedRoleplayId = useSelector(
    (state) => state.user.selectedRoleplay,
  );

  const dispatch = useDispatch();

  const selectedRoleplay = roleplayConfig[selectedRoleplayId];
  const { firstCallViewTone, secondCallViewTone, courseId } = selectedRoleplay;

  const roleplayConfigDynamicData = useSelector(
    (state) => state.roleplayConfigDynamic.data,
  );
  const roleplayConfigDynamicStatus = useSelector(
    (state) => state.roleplayConfigDynamic.status,
  );
  const company = roleplayConfigDynamicData.company;
  const companyDetails = roleplayConfigDynamicData.company_details;
  const level = roleplayConfigDynamicData.level;
  const initialConversationId = roleplayConfigDynamicData.conversation_id;
  const vapiAssistantId = roleplayConfigDynamicData.vapi_id;
  console.log("vapiAssistantId", vapiAssistantId);

  const [isLoading, setIsLoading] = useState(true);

  const vapiRef = useRef(null);

  const [, dispatchMaterialUIController] = useMaterialUIController();

  const initializeComponent = () => {
    setIsLoading(true);
    vapiRef.current = new Vapi(process.env.REACT_APP_VAPI_KEY);
    // vapiRef.current = new MockVapi();
    setupVapiEventListeners(); // Call the function to setup event listeners
  };

  const { authenticatedCurrentUser, finishConversation } =
    useContext(AuthContext);
  // New useEffect hook to dispatch fetchRoleplayConfig when userName updates and is not "Unknown"
  useEffect(() => {
    if (userName !== "Unknown" && roleplayConfigDynamicStatus === "idle") {
      let premiumCharacter = false;
      if (totalRoleplaysToday < 3) {
        premiumCharacter = true;
      }
      console.debug("premiumCharacter", premiumCharacter);
      dispatch(
        fetchRoleplayConfig({
          email: email,
          name: userName,
          courseId: courseId,
          premiumCharacter: premiumCharacter,
          orgId: orgId,
        }),
      );
    }
  }, [userName, dispatch]); // Add any other dependencies if necessary

  useEffect(() => {
    if (roleplayConfigDynamicStatus === "succeeded") {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [roleplayConfigDynamicStatus]);

  //On load of the page, we initialize the component
  useEffect(() => {
    initializeComponent();

    return () => {
      dispatch(setCallState("waiting"));
      dispatch(resetRoleplayConfigDynamic());
      dispatch(resetUpdateStatus());
      vapiRef.current.stop();
      console.log("Component unmounting, disconnecting Vapi.");
    };
  }, []);

  //greeting audio
  const [callHasStarted, setCallHasStarted] = useState(false);
  const { playGreeting } = useGreeting(
    firstCallViewTone,
    secondCallViewTone,
    callHasStarted,
  );

  //end of conversation
  const [isExerciseEndModalOpen, setIsExerciseEndModalOpen] = useState(false);
  const goToAssessment = useAfterCallViewNavigation(setIsExerciseEndModalOpen);
  const processFeedback = useAfterCallViewFeedback();

  useEffect(() => {
    if (isExerciseEndModalOpen && authenticatedCurrentUser) {
      finishConversation(authenticatedCurrentUser.uid, currentConversationId);
      //TODO fix immediatelys
    }
  }, [isExerciseEndModalOpen]);

  const navigate = useNavigate();
  const resetComponent = () => {
    navigate("/redirect");
    // setTimeout(() => navigate("/callview"), 500);
  };

  const handleRestart = (createModal) => {
    if (createModal) {
      resetComponent();
    } else {
      setIsExerciseEndModalOpen(false);
      resetComponent();
    }
  };

  useNavigationPrompt(callHasStarted);

  //set up call state
  // const [isExerciseEnd, setIsExerciseEnd] = useState(false);
  // const currentCallState = useSelector(
  //   (state) => state.callViewState.callState
  // );
  // const determineCallState = (
  //   isRecording,
  //   isPlaying,
  //   isGreetingPlaying,
  //   currentCallState
  // ) => {
  //   if (isRecording) {
  //     console.log("Call State: User is speaking.");
  //     return "user_speaking";
  //   } else if (isGreetingPlaying) {
  //     console.log("Call State: Greeting is playing.");
  //     return "greeting_playing";
  //   } else if (isPlaying) {
  //     console.log("Call State: User is listening.");
  //     return "user_listening";
  //   } else {
  //     if (currentCallState === "user_speaking") {
  //       console.log("Call State: Waiting for response.");
  //       return "waiting_for_response";
  //     } else if (currentCallState === "user_listening") {
  //       console.log(
  //         "Call State: User is now waiting for their turn to speak. Starting..."
  //       );
  //       if (!isExerciseEnd) {
  //         // onStart();
  //       } else {
  //         // onStop();
  //         console.log("End of exercise. Opening modal...");
  //         setIsExerciseEndModalOpen(true);
  //         setIsExerciseEnd(false);
  //         return "end_of_exercise";
  //       }
  //     } else {
  //       console.log("Call State: Waiting.");
  //       return "waiting";
  //     }
  //   }
  // };

  // useEffect(() => {
  //   const newCallState = determineCallState(
  //     isRecording,
  //     isPlaying,
  //     isGreetingPlaying,
  //     currentCallState
  //   );
  //   dispatch(setCallState(newCallState));
  // }, [isRecording, isPlaying, isGreetingPlaying, isExerciseEnd, dispatch]);

  const volumeLevel = useRef(0);

  const setupVapiEventListeners = () => {
    if (!vapiRef.current) return;
    console.log("vapiRef.current updated", vapiRef.current);

    vapiRef.current.on("message", (message) => {
      console.debug(message);
    });

    vapiRef.current.on("error", (e) => {
      console.error(e);
    });

    vapiRef.current.on("volume-level", (volume) => {
      volumeLevel.current = volume;
    });

    vapiRef.current.on("call-end", () => {
      setIsExerciseEndModalOpen(true);
      setCallHasStarted(false);
      console.log("Call has ended.");
    });

    vapiRef.current.on("call-start", () => {
      setCallHasStarted(true);
      setTimeout(() => {
        setMiniSidenav(dispatchMaterialUIController, true);
      }, 1000);
      console.log("Call has started.");
    });

    vapiRef.current.on("speech-end", () => {
      console.log("Assistant speech has ended.");
    });

    vapiRef.current.on("speech-start", () => {
      console.log("Assistant speech has started.");
    });
  };
  return (
    <DashboardLayout>
      <ErrorBoundary
        FallbackComponent={ErrorModal}
        onReset={() => {
          window.location.href = "/";
        }}
        onError={(error, errorInfo) => {
          console.log("ErrorBoundary activated:", error, errorInfo);
          sendErrorEmail(error, errorInfo, userEmail || "Unknown");
        }}
      >
        <CallViewCard
          callHasStarted={callHasStarted}
          handleRestart={handleRestart}
          isExerciseEndModalOpen={isExerciseEndModalOpen}
          goToAssessment={goToAssessment}
          processFeedback={processFeedback}
          volumeLevel={volumeLevel}
        />
        {userName === "Unknown" ? (
          <LoginModal />
        ) : (
          <UserOnboardingModal
            displayName={userName}
            playGreeting={playGreeting}
            isLoading={isLoading}
            company={company}
            companyDetails={companyDetails}
            level={level}
            vapiRef={vapiRef}
            vapiAssistantId={vapiAssistantId}
            initialConversationId={initialConversationId}
          />
        )}
      </ErrorBoundary>
    </DashboardLayout>
  );
};

export default NewView;
