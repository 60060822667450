import { useRef, useEffect, useCallback } from "react";

const useGreeting = (firstCallViewTone, secondCallViewTone, callHasStarted) => {
  const firstAudio = useRef(new Audio(firstCallViewTone));
  const secondAudio = useRef(new Audio(secondCallViewTone));
  const isUnmounted = useRef(false);

  useEffect(() => {
    firstAudio.current.loop = true;
    firstAudio.current.load();

    return () => {
      isUnmounted.current = true;
      firstAudio.current.pause();
      firstAudio.current.currentTime = 0;
    };
  }, []);

  const playGreeting = useCallback(() => {
    if (!isUnmounted.current) {
      firstAudio.current
        .play()
        .catch((error) =>
          console.error("Error playing the first tone:", error),
        );
    }
  }, []);

  const handleCanPlayThrough = useCallback(() => {
    if (callHasStarted && !isUnmounted.current) {
      firstAudio.current.pause();
      firstAudio.current.currentTime = 0;
      secondAudio.current
        .play()
        .catch((error) =>
          console.error("Error playing the second tone:", error),
        );
    }
  }, [callHasStarted]);

  useEffect(() => {
    secondAudio.current.addEventListener(
      "canplaythrough",
      handleCanPlayThrough,
    );
    secondAudio.current.load();

    return () => {
      secondAudio.current.pause();
      secondAudio.current.currentTime = 0;
      secondAudio.current.removeEventListener(
        "canplaythrough",
        handleCanPlayThrough,
      );
    };
  }, [callHasStarted, handleCanPlayThrough]);

  return { playGreeting };
};

export default useGreeting;
