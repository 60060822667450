import React, { useRef, useEffect, useState } from "react";
import videoBackground from "assets/images/background/855441-hd_1920_1080_25fps.mp4";
import videoPlaceholder from "assets/images/background/video_background.png";

function BackgroundVideo() {
  const videoRef = useRef(null);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [playError, setPlayError] = useState(false); // New state for play errors

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 1;
      videoRef.current.oncanplaythrough = () => setVideoLoaded(true);
      // Attempt to play the video programmatically to handle iOS restrictions
      videoRef.current.play().catch((error) => {
        console.warn("Video play failed:", error);
        setVideoLoaded(false);
        setPlayError(true);
      });
    }
  }, []);

  return (
    <>
      <video
        autoPlay="autoplay"
        loop="loop"
        muted
        playsInline
        ref={videoRef}
        style={{
          position: "absolute",
          width: "100%",
          left: 0,
          top: 0,
          height: "100%",
          objectFit: "cover",
          zIndex: -1,
          display: videoLoaded && !playError ? "block" : "none", // Use playError to control display
        }}
        poster={videoPlaceholder}
      >
        <source src={videoBackground} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {(!videoLoaded || playError) && (
        <img
          src={videoPlaceholder}
          alt="Loading video..."
          style={{
            position: "absolute",
            width: "100%",
            left: 0,
            top: 0,
            height: "100%",
            objectFit: "cover",
            zIndex: -1,
          }}
        />
      )}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(27, 159, 191, 0.5)", // Semi-transparent black overlay
          zIndex: 0, // Ensure this is above the video but below other content
        }}
      ></div>
    </>
  );
}

export default BackgroundVideo;
