import React, { useRef, useEffect, useMemo } from "react";

import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Typed from "typed.js";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { useMediaQuery, useTheme } from "@mui/material";
import { Fade, Slide } from "@mui/material";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import CenteredFooter from "components/Footer";
import { setSelectedRoleplay } from "../../features/user/userSlice";
import welcomePageVariants from "../../config/welcomePageConfig";
import PageLayout from "layouts/PageLayout";
import useSequentialVisibility from "hooks/useSequentialVisibility";
import BackgroundVideo from "./BackgroundVideo";

function HeaderOne() {
  const { variant = "default" } = useParams();
  const navigate = useNavigate();

  const pageConfig = welcomePageVariants[variant];

  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const authLevel = useSelector((state) => state.user.authLevel);

  //things that depend on screen size
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handlePlayClick = () => {
    if (!isAuthenticated || authLevel === "general") {
      dispatch(setSelectedRoleplay(pageConfig.selectedRoleplay));
    }
    navigate("/callview"); // Navigate to callview after setting the state
  };

  const handleSeeTheRestClick = () => {
    navigate("/my-simulations");
  };

  //Changing Headline
  const typedJSRef = useRef(null);

  // Setting up typedJS
  useEffect(() => {
    if (typedJSRef.current) {
      // Ensure the ref is attached to a DOM element
      const typedJS = new Typed(typedJSRef.current, {
        strings: [
          "a cold call.",
          "a discovery call.",
          "handling objections.",
          "your pitch.",
        ],
        typeSpeed: 70,
        backSpeed: 70,
        backDelay: 200,
        startDelay: 500,
        loop: true,
      });

      return () => typedJS.destroy();
    }
  }, []);

  const delays = useMemo(() => [100, 300, 400], []);

  const [showPractice, showDescription, showButtons] =
    useSequentialVisibility(delays);

  if (!pageConfig) {
    return <div>Page not found</div>;
  }

  return (
    <PageLayout>
      <MKBox
        component="header"
        position="absolute"
        minHeight="100vh"
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <BackgroundVideo />
        <MKBox
          display="flex"
          alignItems="center"
          minHeight="70vh"
          mt={5}
          style={{ zIndex: 1 }}
        >
          <Container>
            <Grid
              container
              item
              xs={12}
              lg={8}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              mx="auto"
              sx={({ breakpoints }) => ({
                [breakpoints.down("md")]: {
                  mt: 10,
                  mb: 1,
                },
              })}
            >
              <MKTypography variant="body2" color="white">
                {pageConfig.introText}
              </MKTypography>
              <Slide
                direction="down"
                in={showPractice}
                timeout={700}
                style={{
                  transitionTimingFunction: theme.transitions.easing.easeInOut,
                }}
              >
                <Fade in={showPractice} timeout={700}>
                  <Grid container justifyContent="center" alignItems="center">
                    <MKTypography
                      variant="h1"
                      px={{ xs: 3, lg: 8 }}
                      ml={{ xs: 0, lg: 6 }}
                      color="white"
                      sx={({ breakpoints, typography: { size } }) => ({
                        display: "flex", // Use flexbox to align text and span inline
                        justifyContent: "flex-start", // Aligns content to the left within the typography component
                        width: "100%", // Ensures the typography component takes full width of its container
                        textOverflow: "ellipsis", // Adds an ellipsis when text overflows
                        whiteSpace: "nowrap", // Prevents text from wrapping
                        overflow: "visible", // Hides any overflow text
                        [breakpoints.down("md")]: {
                          fontSize: size["2xl"],
                        },
                      })}
                    >
                      Practice&nbsp;
                      <span ref={typedJSRef} />
                    </MKTypography>
                  </Grid>
                </Fade>
              </Slide>
              <Fade in={showDescription} timeout={700}>
                <MKTypography
                  variant="body1"
                  color="white"
                  mt={{ xs: 1, lg: 3 }}
                  mb={6}
                  px={{ xs: 3, lg: 6 }}
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["md"],
                    },
                  })}
                >
                  {pageConfig.description
                    .split(". ")
                    .map((sentence, index, array) => (
                      <React.Fragment key={index}>
                        {sentence}
                        {index < array.length - 1 ? ". " : ""}
                        <br />
                      </React.Fragment>
                    ))}
                </MKTypography>
              </Fade>
              <Fade in={showButtons} timeout={1000}>
                <Stack
                  direction={{ xs: "column", md: "row" }} // vertical on xs, horizontal on md and larger
                  spacing={5}
                  mt={{ xs: 3, lg: 5 }}
                  sx={{
                    alignItems: "center",
                  }}
                >
                  {isSmallScreen ? (
                    <>
                      <MKButton onClick={handlePlayClick} color="white">
                        Start a cold call
                      </MKButton>
                      <MKButton
                        onClick={handleSeeTheRestClick}
                        variant="text"
                        color="white"
                      >
                        main menu
                      </MKButton>
                    </>
                  ) : (
                    <>
                      <MKButton
                        onClick={handleSeeTheRestClick}
                        variant="text"
                        color="white"
                      >
                        Main menu
                      </MKButton>
                      <MKButton onClick={handlePlayClick} color="white">
                        Start a cold call
                      </MKButton>
                    </>
                  )}
                </Stack>
              </Fade>
            </Grid>
          </Container>
        </MKBox>
        <MKBox mt="30vh" minHeight="10vh">
          <CenteredFooter light={true} />
        </MKBox>
      </MKBox>
    </PageLayout>
  );
}

export default HeaderOne;
