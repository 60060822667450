import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { updateConversationId } from "features/roleplayConfigDynamic/roleplayConfigDynamicSlice";
import { AuthContext } from "services/firebase/AuthContext";
import BaseModal from "components/BaseModal/BaseModal";
import LoadingOverlay from "./LoadingOverlay";

const UserOnboardingModal = ({
  playGreeting,
  isLoading,
  company = "Dummy",
  companyDetails = "Dummy",
  level = null,
  vapiRef,
  vapiAssistantId,
  initialConversationId,
}) => {
  const [baseModalOpen, setBaseModalOpen] = useState(true); // Control BaseModal visibility independently
  const [instructionsModalOpen, setInstructionsModalOpen] = useState(false); // New state for instructions modal visibility

  const { authenticatedCurrentUser, updateCurrentConversationId } =
    useContext(AuthContext);

  const dispatch = useDispatch();

  const [error, setError] = useState(null);
  useEffect(() => {
    if (error) {
      throw error;
    }
  }, [error]);

  // Function to handle closing the BaseModal
  const handleBaseModalClose = async () => {
    playGreeting();
    setBaseModalOpen(false);
    try {
      if (!vapiAssistantId) {
        console.error("vapiAssistantId is undefined");
        throw new Error("vapiAssistantId is undefined");
      }
      let vapiLog = await vapiRef.current.start(vapiAssistantId);
      console.debug("vapi assistant id", vapiAssistantId);

      console.debug("Vapi Object started", vapiLog);
      if (!vapiLog.id) {
        console.error("vapiLog is undefined Biatch");
        throw new Error("vapiLog is undefined");
      }
      const newConversationId = vapiLog.id;

      dispatch(
        updateConversationId({
          oldConversationId: initialConversationId,
          newConversationId: newConversationId,
        }),
      ); // to backend
      updateCurrentConversationId(
        authenticatedCurrentUser.uid,
        newConversationId,
      ); //to firebase
    } catch (error) {
      console.error("Microphone access denied", error);
      setError(error);
    }
  };

  const handleInstructionsModalClose = () => {
    setInstructionsModalOpen(false);
    setBaseModalOpen(true);
  };

  const roleplayConfigDynamic = useSelector(
    (state) => state.roleplayConfigDynamic.data,
  );

  // Render the BaseModal
  const renderBaseModal = () => (
    <BaseModal
      isOpen={baseModalOpen}
      title="Instructions for the call"
      onClose={handleBaseModalClose}
      actions={[
        {
          label: `Learn about ${company}`, // New action
          onClick: () => setInstructionsModalOpen(true), // Open the instructions modal
          highlight: false,
        },
        {
          label: "Let's go",
          onClick: handleBaseModalClose, // Ensure this function is defined in your component
          highlight: true,
        },
      ]}
    >
      {(roleplayConfigDynamic.call_instructions || "")
        .split("\n")
        .map((instruction, index) => (
          <p key={index}>{instruction}</p>
        ))}
      {level && (
        <p>
          Level of difficulty: <strong>{level}</strong>
        </p>
      )}{" "}
    </BaseModal>
  );

  const renderInstructionsModal = () => (
    <BaseModal
      isOpen={instructionsModalOpen}
      title={`About ${company}`}
      onClose={handleInstructionsModalClose}
      actions={[
        {
          label: "Back to instructions",
          onClick: handleInstructionsModalClose,
          highlight: true,
        },
      ]}
    >
      {companyDetails.split("\n").map((detail, index) => (
        <p key={index}>{detail}</p>
      ))}
    </BaseModal>
  );

  // New state to hold the modal content
  const [modalContent, setModalContent] = useState(null);

  useEffect(() => {
    // This function now sets the modal content state instead of directly rendering
    const determineModalContent = () => {
      if (instructionsModalOpen) {
        return renderInstructionsModal();
      } else {
        return renderBaseModal();
      }
    };

    // Set the modal content when the component mounts
    setModalContent(determineModalContent());
  }, [baseModalOpen, roleplayConfigDynamic, instructionsModalOpen]);

  // The render function now simply returns the modal content state
  return (
    <>
      <LoadingOverlay isOpen={isLoading} />
      {!isLoading && modalContent}
    </>
  );
};

UserOnboardingModal.propTypes = {
  displayName: PropTypes.string.isRequired,
  playGreeting: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  company: PropTypes.string,
  companyDetails: PropTypes.string,
  level: PropTypes.string,
  vapiRef: PropTypes.object,
  vapiAssistantId: PropTypes.string,
  initialConversationId: PropTypes.string,
};

export default UserOnboardingModal;
