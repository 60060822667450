import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";

import { motion } from "framer-motion";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Grow from "@mui/material/Grow";
import { useTheme } from "@mui/material";
import Divider from "@mui/material/Divider";
import SwipeableViews from "react-swipeable-views";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useMediaQuery } from "@mui/material";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";

import DashboardLayout from "layouts/DashboardLayout";
import RoleplayCard from "components/RoleplayCard";
import AvatarQuestionMark from "assets/images/avatars/question_mark_avatar_1.png";
import SkylarBanner from "assets/images/banners/skylar_banner.jpg";
import scenarioCards from "config/scenarioConfig";
import SignUpModal from "components/SignUpModal";

function groupCardsByCategory(cards) {
  const grouped = {};
  cards.forEach((card) => {
    card.categories.forEach((category) => {
      if (!grouped[category]) {
        grouped[category] = [];
      }
      grouped[category].push(card);
    });
  });
  return grouped;
}

function adjustCategoriesForAuthLevel(cards, authLevel) {
  return cards.map((card) => {
    let categories = card.categories;
    if (authLevel === "multiverse" && card.multiverseCategories) {
      categories = [...card.multiverseCategories];
    }
    return { ...card, categories };
  });
}

// Helper function to chunk the cards array
function chunkArray(array, size) {
  const chunked_arr = [];
  for (let i = 0; i < array.length; i += size) {
    chunked_arr.push(array.slice(i, i + size));
  }
  return chunked_arr;
}

function MySimulations() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [startGrowAnimation, setStartGrowAnimation] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  //signup modal
  const signUpModalRef = useRef(null);

  // Access user data from Redux store
  const { isAuthenticated, displayName, authLevel } = useSelector(
    (state) => state.user,
  );

  // Adjusting cards based on auth level
  const adjustedCards = adjustCategoriesForAuthLevel(scenarioCards, authLevel);
  const filteredCards = adjustedCards.filter((card) =>
    card.authLevels.includes(authLevel),
  );

  const groupedCards = groupCardsByCategory(filteredCards);

  // Adjust how cards are grouped for the swipe view
  const cardsPerSlide = isSmallScreen ? 1 : 3; // Adjust this number based on your design needs
  console.log(cardsPerSlide);
  const chunkedGroupedCards = Object.keys(groupedCards).reduce(
    (acc, category) => {
      acc[category] = chunkArray(groupedCards[category], cardsPerSlide);
      return acc;
    },
    {},
  );

  const cardVariants = {
    hidden: { y: "-250%" },
    visible: {
      y: 0,
      transition: { duration: 1, ease: "easeInOut" },
    },
  };
  const imageVariants = {
    hidden: { clipPath: "inset(0% 0% 100% 0%)" },
    visible: {
      clipPath: "inset(0% 0% 0% 0%)",
      transition: { duration: 1, ease: "easeInOut" },
    },
  };

  return (
    <DashboardLayout>
      <MKBox>
        <MKBox position="relative" mb={5}>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={imageVariants}
          >
            <MKBox
              display="flex"
              alignItems="center"
              position="relative"
              minHeight="18.75rem"
              borderRadius="xl"
              sx={{
                backgroundImage: `url(${SkylarBanner})`,
                backgroundSize: "cover",
                backgroundPosition: "50%",
                overflow: "hidden",
              }}
            />
          </motion.div>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={cardVariants}
            onAnimationComplete={() => setStartGrowAnimation(true)}
          >
            <Card
              sx={{
                position: "relative",
                mt: -8,
                mx: 3,
                py: 2,
                px: 2,
              }}
            >
              <Grid container spacing={3} alignItems="center">
                <Grid item>
                  <MKAvatar
                    src={AvatarQuestionMark}
                    alt="profile-image"
                    size="xl"
                    shadow="sm"
                  />
                </Grid>
                <Grid item>
                  <MKBox height="100%" mt={0.5} lineHeight={1}>
                    <MKTypography variant="h5" fontWeight="medium">
                      {isAuthenticated ? displayName : "Your Name Here"}
                    </MKTypography>
                    {isAuthenticated ? (
                      <MKTypography
                        variant="button"
                        color="text"
                        fontWeight="regular"
                      >
                        {authLevel === "general"
                          ? "Free User"
                          : authLevel.charAt(0).toUpperCase() +
                            authLevel.slice(1)}
                      </MKTypography>
                    ) : (
                      <MKTypography
                        variant="button"
                        color="primary"
                        fontWeight="regular"
                        onClick={() => signUpModalRef.current.open()}
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                      >
                        Register to get started
                      </MKTypography>
                    )}
                  </MKBox>
                </Grid>
              </Grid>
            </Card>
          </motion.div>
        </MKBox>
        <Container>
          {Object.keys(groupedCards).map((category) => (
            <MKBox key={category} mb={3}>
              <MKTypography variant="h3" mb={2} pt={2} pb={3}>
                {category}
              </MKTypography>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}
              >
                <Grid item>
                  <IconButton onClick={handleBack} disabled={activeStep === 0}>
                    <ArrowBackIosIcon />
                  </IconButton>
                </Grid>
                <Grid item xs>
                  <SwipeableViews
                    index={activeStep}
                    onChangeIndex={(step) => setActiveStep(step)}
                    enableMouseEvents
                  >
                    {chunkedGroupedCards[category].map((chunk, index) => (
                      <Grid container spacing={4} key={index}>
                        {chunk.map((card) => (
                          <Grow
                            in={startGrowAnimation}
                            timeout={1000}
                            key={card.title}
                            style={{
                              transformOrigin: "top left",
                              transitionTimingFunction:
                                theme.transitions.easing.easeInOut,
                            }}
                          >
                            <Grid item xs={12} md={6} lg={4}>
                              <RoleplayCard
                                color={card.color}
                                title={card.title}
                                description={card.description}
                                percentage={card.percentage}
                                duedate={card.duedate}
                                avatars={card.avatars.map((avatar) => ({
                                  src: avatar,
                                }))}
                                selectedRoleplay={card.selectedRoleplay}
                              />
                            </Grid>
                          </Grow>
                        ))}
                      </Grid>
                    ))}
                  </SwipeableViews>
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={handleNext}
                    disabled={
                      activeStep === chunkedGroupedCards[category].length - 1
                    }
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Divider sx={{ mt: 3 }} />
            </MKBox>
          ))}
        </Container>
        <SignUpModal ref={signUpModalRef} />
      </MKBox>
    </DashboardLayout>
  );
}

export default MySimulations;
